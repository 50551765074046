@import 'normalize.css';
@import 'fonts.css'

html, body;{
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    color: #444;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;
    text-underline: none;
}
body {
    background: rgb(240, 241, 242);
    font-family: 'Manrope', sans-serif;
}
* {
    box-sizing: border-box;
    text-decoration: none;
    text-underline: none;
    margin: 0;
    padding: 0;
    font-family: 'Manrope', sans-serif;
}

#preact_root {
    height: 100%;
}

a {
    text-decoration: none;
    text-underline: none;
}
